package com.steamstreet.vegasful.browser.account

import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.graphql.api.ClearUserDataInput
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.TagConsumer
import kotlinx.html.button
import kotlinx.html.js.onClickFunction
import kotlinx.html.p

/**
 * Show the account delete option.
 */
fun <T> TagConsumer<T>.accountDelete(context: CoroutineScope) {
    accountDialog("Delete Account") {
        p {
            +("Submit here to request that your account be deleted and all data associated with your account be deleted.")
        }
        p {
            +"This operation cannot be undone."
        }

        button {
            css {
                width = 100.pct
                display = Display.flex
                fontSize = 18.px
                fontWeight = FontWeight.w600
                padding(8.px)
                borderRadius = 5.px
                marginTop = 8.px
                cursor = Cursor.pointer
                +VegasfulStyles.largeButton
            }
            onClickFunction = {
                context.launch {
                    try {
                        GraphQL.mutation {
                            subscriptions {
                                clear(ClearUserDataInput())
                            }
                        }.subscriptions.clear
                        window.alert("Your account has been deleted. You will be logged out now.")
                        Account.logout()
                    } catch (t: Throwable) {
                        window.alert("We weren't able to clear the account. Please contact support.")
                    }
                }
            }
            +"Delete Account"
        }
    }
}