package com.steamstreet.vegasful.browser.account

import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import org.w3c.dom.Element

fun initAccountMenu(retry: Boolean = true) {
    println("INIT ACCOUNT MENU")
    val accountMenuElement = document.querySelector("[data-module=account-menu]")
    if (accountMenuElement != null) {
        accountMenu(accountMenuElement)
    } else {
        // the page might not be ready, so retry when the page has been loaded.
        if (retry) {
            onReady {
                initAccountMenu(false)
            }
        }
    }
}

fun accountMenu(element: Element) {
    val link = document.createElement("a")
    link.addClass("subtle-link")

    if (Account.isLoggedIn()) {
        link.textContent = "Sign Out"
        link.setAttribute("href", "#")
        link.addEventListener("click", {
            it.preventDefault()
            Account.logout()
        })
    } else {
        link.setAttribute("href", "#")
        link.textContent = "Sign In"
        link.addEventListener("click", {
            it.preventDefault()
            Account.login(window.location.href)
        })
    }
    element.appendChild(link)
}
