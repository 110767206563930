package com.steamstreet.vegasful.browser.account

import org.w3c.dom.Navigator
import kotlin.js.Promise

// Define the ShareData interface
external interface ShareData {
    var title: String?
    var text: String?
    var url: String?
}

// Extend the Navigator interface to include share function
external interface NavigatorShare {
    fun share(data: ShareData): Promise<Unit>
    val canShare: Boolean
}

// Extension property to cast Navigator to NavigatorShare
val Navigator.shareCapabilities: NavigatorShare?
    get() = this.asDynamic().unsafeCast<NavigatorShare?>()

// Extension function to make sharing easier
fun Navigator.safeShare(
    title: String? = null,
    text: String? = null,
    url: String
): Promise<Unit>? {

    val shareData: dynamic = js("({})")
    shareData["url"] = url
    text?.let { shareData["text"] = it }
    title?.let { shareData["title"] = it }
    console.dir(shareData)

    return shareCapabilities?.let { share ->
        if (share.canShare) {
            share.share(shareData)
        } else {
            null
        }
    }
}