package com.steamstreet.vegasful.browser.account.subscriptions

import com.steamstreet.graphkt.client.GraphQLClientException
import com.steamstreet.vegasful.browser.account.Account
import com.steamstreet.vegasful.browser.account.GraphQL
import com.steamstreet.vegasful.browser.account.config
import com.steamstreet.vegasful.browser.account.safeShare
import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.graphql.api.LikeInput
import com.steamstreet.vegasful.graphql.api.SubscriptionInput
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import kotlinx.html.classes
import kotlinx.html.dom.append
import kotlinx.html.js.div
import kotlinx.html.js.i
import kotlinx.html.js.onClickFunction
import org.w3c.dom.Element
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventTarget
import kotlin.properties.Delegates.observable

val eventHandlerContext = CoroutineScope(Dispatchers.Default)
fun EventTarget.onClick(handler: suspend (Event) -> Unit) {
    addEventListener("click", {
        eventHandlerContext.launch {
            handler(it)
        }
    })
}

const val subscribeIcon = "fa-heart"

class EntityInteractionsController(private val context: CoroutineScope, val element: Element) {
    private val entity = element.getAttribute("data-entity-path")
    private val entityName = element.getAttribute("data-entity-name")

    private var isLiked: Boolean? by observable(null) { _, _, new ->
        context.launch {
            updateLikeButton(new)
        }
    }

    private fun appendButton(iconType: String, icon: String, onClick: suspend () -> Unit) {
        element.append {
            div {
                css {
                    display = Display.inlineBlock
                    color = VegasfulStyles.pink
                    fontSize = 1.4.em
                    marginRight = .5.em
                }
                i {
                    classes = setOf(iconType, icon)
                }
                onClickFunction = {
                    context.launch {
                        onClick()
                    }
                }
            }
        }
    }

    private suspend fun updateLikeButton(subscribed: Boolean?) {
        if (subscribed != null) {
            val subscribedButton = element.querySelector(".${subscribeIcon}")
            if (subscribedButton != null) {
                if (subscribed) {
                    subscribedButton.removeClass("fa-regular")
                    subscribedButton.addClass("fa-solid")
                } else {
                    subscribedButton.removeClass("fa-solid")
                    subscribedButton.addClass("fa-regular")

                }
            }
        }
    }

    private suspend fun unlike() {
        if (entity == null) return

        val oldSubscribed = isLiked
        isLiked = false
        try {
            GraphQL.mutation {
                subscriptions {
                    unsubscribe(SubscriptionInput(null, entity))
                }
            }
        } catch (t: Throwable) {
            isLiked = oldSubscribed
        }
    }

    private suspend fun like() {
        if (entity == null) return

        if (!Account.isLoggedIn()) {
            Account.login("${entity}?subscribe=1")
        }

        val oldLiked = isLiked
        isLiked = true
        try {
            GraphQL.mutation {
                subscriptions {
                    like(LikeInput(entity))
                }
            }
        } catch (t: Throwable) {
            isLiked = oldLiked
        }
    }

    private suspend fun checkLikeStatus() {
        if (entity == null) return

        if (!Account.isLoggedIn()) {
            isLiked = false
            return
        }

        try {
            GraphQL.client {
                subscriptions {
                    mine {
                        interactionsWithEntity(entity) {
                            this.date
                        }
                    }
                }
            }.let {
                val loggedIn = it.subscriptions.mine != null
                val interactions = it.subscriptions.mine?.interactionsWithEntity

                isLiked = !interactions.isNullOrEmpty()

                if (loggedIn && interactions.isNullOrEmpty()) {
                    val shouldSubscribe = document.location?.href?.substringAfter("?")?.split("&")?.map {
                        it.split("=").let {
                            if (it.size == 1) it.first() to it.first()
                            else it[0] to it[1]
                        }
                    }?.toMap()?.get("subscribe") == "1"

                    if (shouldSubscribe) {
                        isLiked = true // assume the subscription is going to work
                        like()
                    }
                }
            }
        } catch (ce: GraphQLClientException) {
            isLiked = false
        }
    }

    suspend fun share() {
        window.navigator.safeShare(
            url = "https://${config.domain}${entity}"
        )
    }

    suspend fun run() {
        appendButton("fa-regular", "fa-heart") {
            if (isLiked == true) {
                unlike()
            } else {
                like()
            }
        }
        appendButton("fa-solid", "fa-solid fa-arrow-up-from-bracket") {
            share()
        }
        checkLikeStatus()
    }
}