package com.steamstreet.vegasful.browser.account

import com.steamstreet.vegasful.css.css
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import org.w3c.dom.url.URLSearchParams

fun FlowContent.loginPanel() {
    fun FlowContent.loginButton(name: String, color: Color, provider: String) {
        a(href = "#") {
            css {
                width = 100.pct
                display = Display.flex
                this.color = Color.white
                backgroundColor = color
                fontSize = 18.px
                fontWeight = FontWeight.w600
                padding(8.px)
                borderRadius = 5.px
                marginTop = 8.px

            }
            onClickFunction = {
                Account.authenticate(provider.takeIf { it != "Cognito" })
            }
            img(src = "/assets/ui/identity/${provider}.png") {
                css {
                    width = 40.px
                    verticalAlign = VerticalAlign.middle
                    marginRight = 8.px
                }
            }
            span {
                css {
                    flexGrow = 1.0
                    textAlign = TextAlign.center
                    paddingTop = 9.px
                }
                +name
            }
        }
    }

//        loginButton("Continue with Email/Password", Color("#4267B2"), "Cognito")
    loginButton("Continue with Facebook", Color("#4267B2"), "Facebook")
//        loginButton("Continue with Apple", Color("#000000"), "Apple")
    loginButton("Continue with Google", rgb(220, 38, 38), "Google")
}

/**
 * Render and manage the login dialog.
 */
fun <T> TagConsumer<T>.loginDialog(message: String, title: String) {
    accountDialog(title, {
        +message
    }, {
        loginPanel()
    })
}

/**
 * Handle redirect from authentication
 */
suspend fun postAuth() {
    val code = URLSearchParams(window.location.search).get("code")
    if (code != null) {
        Account.authorize(TokenType.AUTHORIZATION_CODE, code)
    }

    val redirect = Account.getLoginReturnUrl() ?: window.origin
    window.location.href = redirect
}