package com.steamstreet.vegasful.browser.account.intelligence

import com.steamstreet.vegasful.browser.account.Account
import com.steamstreet.vegasful.browser.account.GraphQL
import com.steamstreet.vegasful.browser.account.accountDialog
import com.steamstreet.vegasful.browser.account.loginDialog
import com.steamstreet.vegasful.browser.account.subscriptions.event
import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.graphql.api.ConversationInput
import com.steamstreet.vegasful.graphql.api.ConversationStatus
import com.steamstreet.vegasful.graphql.api.client.Conversation
import kotlinx.browser.document
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.dom.clear
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.onClickFunction
import org.w3c.dom.Element
import org.w3c.dom.HTMLTextAreaElement
import kotlin.time.Duration.Companion.seconds

private const val intelligenceInputId: String = "intelligence-input"
private const val statusId: String = "intelligence-status"

/**
 * Manages the Intelligence screen
 */
class Intelligence(private val element: Element, private val scope: CoroutineScope) {
    fun run() {
        element.append {
            if (!Account.isLoggedIn()) {
                loginDialog("To use Vegasful Intelligence, you must have a Vegasful account", "Login")
            } else {
                accountDialog("Vegasful Intelligence", {}) {
                    textArea {
                        this.id = intelligenceInputId
                        placeholder = "How can Vegasful help you today?"
                        css {
                            width = 100.pct
                            height = 200.px
                            padding(10.px)
                            borderRadius = 10.px
                        }
                    }

                    intelligenceButton("Ask Vegasful") {
                        val query = (document.getElementById(intelligenceInputId) as? HTMLTextAreaElement)?.value
                        if (query != null) {
                            scope.launch {
                                executeQuery(query)
                            }
                        }
                    }
                }
            }
        }
    }


    private suspend fun executeQuery(query: String) {
        element.clear()
        element.append {
            accountDialog("Vegasful Intelligence", {
                p {
                    css {
                        fontWeight = FontWeight.w600
                        fontSize = 18.px
                        textAlign = TextAlign.left
                    }
                    +query
                }
            }) {
                div {
                    id = statusId
                    +"Analyzing Vegas events..."
                }
            }
        }

        console.log("Running query: $query")
        val id = GraphQL.client {
            conversations {
                start(ConversationInput(query)) {
                    id
                }
            }
        }.conversations.start.id

        val result = pollForConversation(id)
        if (result?.status == ConversationStatus.COMPLETE) {
            renderResult(result, document.getElementById(statusId)!!)
        } else {
            renderError(document.getElementById(statusId)!!)
        }
    }

    private suspend fun renderError(element: Element) {

    }

    private suspend fun renderResult(conversation: Conversation, status: Element) {
        status.clear()
        status.append {
            conversation.blocks?.forEach { block ->
                div {
                    if (block.event != null) {
                        event(block.event!!) {
                            block.content?.let {
                                p {
                                    +it
                                }
                            }
                        }
                    } else {
                        block.content?.let {
                            p {
                                +it
                            }
                        }
                    }
                }
            }
        }
        console.dir(conversation)
    }

    private suspend fun pollForConversation(id: String): Conversation? {
        try {
            return withTimeout(60.seconds) {
                var result: Conversation?

                do {
                    delay(2.seconds)
                    result = GraphQL.client {
                        conversations {
                            conversation(id) {
                                status
                                blocks {
                                    content
                                    event {
                                        this.id
                                        this.name
                                        this.path
                                        this.images {
                                            this.withWidth(768) {
                                                uri
                                            }
                                        }
                                        this.venue {
                                            this.id
                                            this.name
                                            this.path
                                        }
                                        this.date
                                        this.time
                                    }
                                }
                            }
                        }
                    }.conversations.conversation
                } while (result?.status == ConversationStatus.RUNNING)

                result
            }
        } catch (e: TimeoutCancellationException) {
            return null
        }
    }

    private fun FlowContent.intelligenceButton(name: String, onClick: () -> Unit) {
        a(href = "#") {
            css {
                width = 100.pct
                display = Display.flex
                this.color = Color.white
                backgroundColor = VegasfulStyles.purple
                fontSize = 18.px
                fontWeight = FontWeight.w600
                padding(8.px)
                borderRadius = 5.px
                marginTop = 8.px

            }
            onClickFunction = {
                onClick()
            }
            span {
                css {
                    flexGrow = 1.0
                    textAlign = TextAlign.center
                }
                +name
            }
        }
    }
}

/**
 * Control the intelligence page.
 */
suspend fun intelligence(element: Element, scope: CoroutineScope): Unit = coroutineScope {

}
